<template>
  <div>
    <a-modal
      v-model="visibleModalManageSchoolYear"
      class="mod"
      :title="
        status.isEdit ? 'Form Edit Semester' : 'Form Create Semester'
      "
      @cancel="toggleModalManageSchoolYear"
      centered
    >
      <form-school-year
        :dataSchoolYear="newSchoolYear"
        :allDataSchoolYear="schoolYearDataTable"
        :formStatus="status"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageSchoolYear">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageSchoolYear"
          @click="handleOkModalManageSchoolYear"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManageSchoolYear('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Semester</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="columns"
        :pagination="schoolYearPagination"
        :data-source="schoolYearDataTable"
        @change="handleTableChange"
        :loading="schoolYearLoadingTable">
        <div slot="tahun" slot-scope="text"><b>{{text}}</b></div>
        <a-table
          slot="expandedRowRender"
          slot-scope="value"
          :pagination="false"
          :data-source="value.semesters"
          :columns="schoolYearColumns"
          bordered
        >
        <div slot="status" slot-scope="value, record">
          <a-tag :color="record.status === 'aktif' ? 'green' : 'red'">{{record.status === 'aktif' ? 'active' : 'inactive'}}</a-tag>
        </div>
          <div slot="action" slot-scope="value, record">
            <router-link :to="{ name: 'Detail School Year Admin', params: { id: record.id }}">
              <a-button
                size="large"
                block
                icon='container'
                class="mb-3"
                >Manage</a-button
              >
            </router-link>
            <a-button
              block
              v-if="!record.hasSlot"
              @click.prevent="toggleModalManageSchoolYear('edit', record)"
              class="text-warning border border-warning"
              size="large"
            >
              <a-icon type="edit" />Edit
            </a-button>
            <a-tooltip v-else class="w-100">
              <template slot="title">
                <span>Already has schedule.</span>
              </template>
              <a-button
                block
                disabled
                size="large"
              >
                <a-icon type="edit" />Edit
              </a-button>
            </a-tooltip>
            <!-- <a-button
              @click.prevent="handleDeleteSchoolYear(record)"
              class="text-danger border border-danger"
              size="large"
            >
              <a-icon type="delete" />Delete
            </a-button> -->
          </div>
        </a-table>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
const formSchoolYear = () => import('@/components/app/Admin/Master/Form/SchoolYear')

const columns = [
  {
    title: 'Academic Year',
    dataIndex: 'tahun',
    key: 'tahun',
    scopedSlots: { customRender: 'tahun' },
    align: 'center',
  },
]

const schoolYearColumns = [
  {
    title: 'Semester',
    dataIndex: 'semester',
    key: 'semester',
    // scopedSlots: { customRender: 'tahun' },
    align: 'center',
  },
  {
    title: 'Date Active',
    key: 'activeDate',
    children: [
      {
        title: 'Start',
        dataIndex: 'tanggal_awal_string',
        key: 'tanggal_awal',
        scopedSlots: { customRender: 'tanggal_awal' },
        align: 'center',
      },
      {
        title: 'End',
        dataIndex: 'tanggal_akhir_string',
        key: 'tanggal_akhir',
        scopedSlots: { customRender: 'tanggal_akhir' },
        align: 'center',
      },
    ],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  name: 'SchoolYear',
  components: {
    formSchoolYear,
  },
  data() {
    return {
      schoolYearColumns,
      columns,
      visibleModalManageSchoolYear: false,
      loadingActionModalManageSchoolYear: false,
      loadingDeleteSchoolYear: false,
      schoolYearLoadingTable: false,
      schoolYearDataTable: [],
      schoolYearPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newSchoolYear: {
        tahun_awal: null,
        tahun_akhir: null,
        semester: null,
        tanggal_awal: null,
        tanggal_akhir: null,
        statusDuplicate: false,
      },
      idEdit: null,
    }
  },
  methods: {
    handleChange(payload) {
      // console.log('handleChange', payload)
      const { value, column } = payload
      if (Array.isArray(column)) {
        column.forEach((col, i) => {
          this.newSchoolYear[col] = value[i]
        })
      } else {
        if (column.split('_').includes('tahun')) {
          const tahun = column.split('_')
          if (!value) {
            this.newSchoolYear.tahun_awal = null
            this.newSchoolYear.tahun_akhir = null
            this.newSchoolYear.tanggal_awal = null
            this.newSchoolYear.tanggal_akhir = null
          } else {
            if (tahun[1] === 'awal') this.newSchoolYear.tahun_akhir = `${Number(value) + 1}`
            else if (tahun[1] === 'akhir') this.newSchoolYear.tahun_awal = `${Number(value) + 1}`
            this.newSchoolYear[column] = value
          }
        } else this.newSchoolYear[column] = value
      }
    },
    toggleModalManageSchoolYear(status, data) {
      this.visibleModalManageSchoolYear = !this.visibleModalManageSchoolYear
      if (!this.visibleModalManageSchoolYear) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newSchoolYear = {
            tahun_awal: null,
            tahun_akhir: null,
            semester: null,
            tanggal_awal: null,
            tanggal_akhir: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          const tahun = data.tahun.split(' - ')
          this.newSchoolYear = {
            tahun_awal: tahun[0],
            tahun_akhir: tahun[1],
            semester: data.semester,
            tanggal_awal: moment(data.tanggal_awal, 'YYYY-MM-DD'),
            tanggal_akhir: moment(data.tanggal_akhir, 'YYYY-MM-DD'),
          }
        }
      }
    },
    async handleOkModalManageSchoolYear() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this semester?' : 'Are you sure want to create new semester?'}`
      if (this.isValidateForm.isValidate) {
        this.$notification.error({
          message: 'Warning',
          description: this.isValidateForm.message,
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: async () => {
            try {
              this.loadingActionModalManageSchoolYear = true
              const payload = this.status.isEdit ? {
                id: this.idEdit,
                dataSchoolYear: this.newSchoolYear,
              } : {
                dataSchoolYear: this.newSchoolYear,
              }
              const res = await this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_SCHOOL_YEAR`, payload)
              if (this.status.isCreate && (this.newSchoolYear.statusDuplicate === 'true' || this.newSchoolYear.statusDuplicate === true)) {
                this.handleDuplicate(res.data)
              }
              this.loadingActionModalManageSchoolYear = false
              this.toggleModalManageSchoolYear()
              this.fetchDataSchoolYear()
              const description = this.status.isEdit ? 'Semester is updated' : 'Semester is created'
              this.$notification.success({
                message: 'Success',
                description,
              })
            } catch (err) {
              this.loadingActionModalManageSchoolYear = false
              if (err.response.data) {
                const { message } = err.response.data
                return this.$notification.error({
                  message: 'Failed',
                  description: message,
                })
              }
              const description = this.status.isEdit ? 'Semester is not updated' : 'Semester is not created'
              this.$notification.error({
                message: 'Failed',
                description,
              })
            }
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDuplicate(val) {
      // console.log('handleDuplicate', val)
      const idTahunAjaran = val.data.id
      this.$store.dispatch('master/POST_DUPLICATE_FROM_FIRST_SEMESTER', idTahunAjaran)
        .then(isSuccess => {
          if (isSuccess) {
            this.$notification.success({
              message: 'Success',
              description: 'Success duplicate data from first semester',
            })
          } else {
            this.$notification.error({
              message: 'Failed',
              description: 'failed to duplicate data from first semester',
            })
          }
          this.fetchDataSchoolYear()
        })
    },
    handleDeleteSchoolYear(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this semester?</div>
        ),
        onOk: () => {
          this.schoolYearLoadingTable = true
          this.loadingDeleteSchoolYear = true
          this.$store.dispatch('master/DELETE_SCHOOL_YEAR', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataSchoolYear()
              this.loadingDeleteSchoolYear = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Semester is deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Semester is not deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteSchoolYear = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      this.sortBy = sorter.columnKey === 'Name' ? 'nama' : sorter.columnKey
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        this.order = 'ASC'
      } else if (sorter.order === 'descend') {
        this.order = 'DESC'
      } else {
        this.order = 'ASC'
      }
      this.fetchDataSchoolYear({ page: pagination.current })
    },
    async fetchDataSchoolYear(params = { page: 1, order: 'DESC', search: '', type: '', sortBy: '' }) {
      this.schoolYearLoadingTable = true
      const res = await this.$store.dispatch('master/FETCH_SCHOOL_YEAR', { page: params.page, order: this.order, sortBy: this.sortBy, tipe: 'table_admin' })
      this.schoolYearLoadingTable = false
      const pagination = { ...this.schoolYearPagination }
      pagination.total = res.total
      this.schoolYearPagination = pagination
      // console.log(res)
      this.schoolYearDataTable = res.data.map((el, i) => {
        // console.log(el)
        const semesters = el.semesters.map(semester => {
          return {
            ...semester,
            key: semester.id,
            tanggal_awal_string: moment(semester.tanggal_awal, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY'),
            tanggal_akhir_string: moment(semester.tanggal_akhir, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY'),
          }
        })
        return {
          ...el,
          key: i,
          semesters,
        }
      })
    },
  },
  created() {
    this.fetchDataSchoolYear()
  },
  computed: {
    isValidateForm() {
      if (!this.newSchoolYear.tahun_awal || !this.newSchoolYear.tahun_akhir || !this.newSchoolYear.semester || !this.newSchoolYear.tanggal_awal || !this.newSchoolYear.tanggal_akhir) {
        return { isValidate: true, message: 'All Fields Are Required!' }
      } else if (String(this.newSchoolYear.tahun_awal).length !== 4) {
        return { isValidate: true, message: 'Years musts be correct.' }
      }
      return { isValidate: false }
    },
  },
}
</script>

<style>
</style>
